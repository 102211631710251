// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/tailorwine_slider_button.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ":root{--primary-color:#621940;--secondary-color:#f77a7a;--background-color:#ebe8e1;--secondary-color-light:#e1e1da;--primary-font-color:#333;--secondary-font-color:#f77a7a;--disabled-button-background-color:#ccc;--disabled-button-font-color:#fff;--progress-bar-background-color:#f77a7a;--fancy-button-stop-color:#f77a7a;--stepless-slider-background-color:#f77a7a;--primary-font-family:\"Zilla Slab\";--secondary-font-family:\"Open Sans\";--primary-font-weight:bold;--secondary-font-weight:400;--slider-thumb:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");--wizard-first-color-wave:#1c1027;--wizard-second-color-wave:#90173e;--wizard-primary-color:#7b163a;--wizard-secondary-color:#1d74f5;--wizard-font-color-primary:#fff;--wizard-font-color-button:#fff;--wizard-background-color-list-selection:#fff;--wizard-font-color-secondary:#000}button{cursor:pointer;background-color:var(--primary-color);margin:0 1rem;border:none;color:var(--background-color);border-radius:30px;font-weight:700;max-width:225px;padding:13px 35px 15px;text-transform:uppercase;height:5vh;font-family:var(--secondary-font-family),Open Sans,sans-serif;font-style:normal;letter-spacing:2px;transition:all .1s ease-in-out;text-align:center;display:flex;align-items:center;justify-content:center}button:hover{opacity:.8}button.next:disabled{background-color:var(--disabled-button-background-color);color:var(--disabled-button-font-color);opacity:.4}.chip{cursor:pointer;border:1px solid var(--primary-color);color:var(--primary-color);border-radius:50px;text-transform:uppercase;margin:1rem;padding:13px 35px 15px;height:2.5rem;font-family:var(--secondary-font-family),Open Sans,sans-serif;font-style:normal;letter-spacing:2px;transition:all .1s ease-in-out;display:flex;justify-content:center;align-items:center}.chip.selected,.chip:hover{background-color:var(--primary-color);color:#fff}", ""]);
// Exports
module.exports = exports;
