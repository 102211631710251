import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70adc776"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "headSection" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { style: {"margin":"0"} }
const _hoisted_4 = {
  key: 0,
  class: "contentSection"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 1,
  class: "contentSection"
}
const _hoisted_7 = { class: "sended" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkmark = _resolveComponent("Checkmark")!

  return (_openBlock(), _createElementBlock("div", {
    class: "backdropOverlay",
    style: _normalizeStyle({ borderRadius: _ctx.borderRadius ?? '1rem' })
  }, [
    _createElementVNode("div", {
      class: "overlayContainer",
      style: _normalizeStyle({ width: _ctx.widthBlur ?? '100%', height: _ctx.heightBlur ?? '100%' })
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("small", null, _toDisplayString(_ctx.restaurant.DisplayedTexts["Oh, das ist sehr schade."]), 1),
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.restaurant.DisplayedTexts["Woran lag es?"]), 1)
        ]),
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = (e) => _ctx.$emit('closeClick', e)),
          class: "closeButton"
        }, " x ")
      ]),
      (!_ctx.feedbackSended)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeReasonArray, (reason) => {
              return (_openBlock(), _createElementBlock("button", {
                class: "wineButton",
                key: reason.Value,
                onClick: e => {e.stopPropagation(); _ctx.reasonSelected(reason.Value.toString())}
              }, _toDisplayString(reason.Title), 9, _hoisted_5))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("button", _hoisted_7, [
              _createTextVNode(_toDisplayString(_ctx.restaurant.DisplayedTexts["Gespeichert"]) + " ", 1),
              _createVNode(_component_Checkmark, {
                fill: "white",
                class: "checkmark"
              })
            ])
          ]))
    ], 4)
  ], 4))
}