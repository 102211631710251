import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9a15e83a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 2,
  class: "winecard"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 4,
  class: "messageActions"
}
const _hoisted_5 = {
  key: 5,
  style: {"{{     \n\t\t\t\tcolor":"black","width":"100%","display":"flex","justify-content":"space-between"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WineCardV2 = _resolveComponent("WineCardV2")!
  const _component_WineCard = _resolveComponent("WineCard")!
  const _component_Arrow = _resolveComponent("Arrow")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["singleMessage", { sendedFromWilli: _ctx.isMessageSenderWilli, wineCard: _ctx.message.Type === 'WINECARD' || _ctx.message.Type === 'OFFERCARD' }])
  }, [
    (_ctx.isMessageSenderWilli &&
			(_ctx.message.Type === 'NORMAL' || _ctx.message.Type === 'IGNOREINHISTORY')
			)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["avatarContainer", { center: _ctx.restaurant.FrontendDesign?.WineWizard?.AvatarCentered, border: _ctx.chatbotTexts?.showIconBorder === 'true' }]),
          style: _normalizeStyle({ backgroundColor: _ctx.restaurant.FrontendDesign?.WineWizard?.IconBackgroundColor ?? _ctx.restaurant.FrontendDesign?.WineWizard?.PrimaryColor })
        }, [
          _createElementVNode("img", { src: _ctx.avatar }, null, 8, _hoisted_1)
        ], 6))
      : _createCommentVNode("", true),
    (_ctx.message.Type === 'NORMAL' || _ctx.message.Type === 'IGNOREINHISTORY')
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["message", {
			sendedFromWilli: _ctx.isMessageSenderWilli,
			normal: _ctx.message.Type === 'NORMAL' || _ctx.message.Type === 'IGNOREINHISTORY',
		}])
        }, _toDisplayString(_ctx.message.Text), 3))
      : (_ctx.message.Type === 'WINECARD' || _ctx.message.Type === 'OFFERCARD')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_ctx.restaurant.FrontendDesign?.WineCardVersion === 'SECOND')
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.filteredWineResults?.slice(0, 5), (wine, index) => {
                  return (_openBlock(), _createBlock(_component_WineCardV2, {
                    key: wine.Name,
                    restaurant: _ctx.restaurant,
                    "is-card-flipped": index === 0 && _ctx.firstV2CardFlipped,
                    wine: (wine ?? {} as Wine),
                    stylingVariant: _ctx.stylingVariant,
                    onWineClicked: _ctx.wineClicked,
                    onWineFeedbackCauseSelected: _ctx.wineFeedbackCauseSelected,
                    showOfferMode: _ctx.message.Type === 'OFFERCARD'
                  }, null, 8, ["restaurant", "is-card-flipped", "wine", "stylingVariant", "onWineClicked", "onWineFeedbackCauseSelected", "showOfferMode"]))
                }), 128))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.filteredWineResults?.slice(0, 5), (wine) => {
                  return (_openBlock(), _createBlock(_component_WineCard, {
                    key: wine.Name,
                    restaurant: _ctx.restaurant,
                    wine: (wine ?? {} as Wine),
                    stylingVariant: _ctx.stylingVariant,
                    onWineClicked: _ctx.wineClicked,
                    onWineFeedbackCauseSelected: _ctx.wineFeedbackCauseSelected,
                    showOfferMode: _ctx.message.Type === 'OFFERCARD'
                  }, null, 8, ["restaurant", "wine", "stylingVariant", "onWineClicked", "onWineFeedbackCauseSelected", "showOfferMode"]))
                }), 128))
          ]))
        : (_ctx.message.Type === 'ANSWERSELECTION')
          ? (_openBlock(), _createElementBlock("div", {
              key: 3,
              class: _normalizeClass(["messageActions answerSelection", { 'row': (_ctx.message.AnswerSelection?.length && _ctx.message.AnswerSelection?.length > 5) }])
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.message.AnswerSelection, (answer) => {
                return (_openBlock(), _createElementBlock("button", {
                  class: _normalizeClass(["secondary", _ctx.message.SecondaryButtonStyle]),
                  key: answer.Id,
                  onClick: ($event: any) => (_ctx.chatbot.SetAnswerForQuiz(_ctx.restaurant, answer, _ctx.restaurant.RestaurantQuizConfig.ExtraChatBotQuiz))
                }, _toDisplayString(answer.Text), 11, _hoisted_3))
              }), 128))
            ], 2))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("button", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.message.PrimaryFunction && _ctx.message.PrimaryFunction())),
                class: _normalizeClass(_ctx.message.PrimaryButtonStyle)
              }, _toDisplayString(_ctx.message.Text), 3),
              (_ctx.message.Type === 'TWOBUTTONS')
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: _normalizeClass(["secondary", _ctx.message.SecondaryButtonStyle]),
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.message.SecondaryFunction && _ctx.message.SecondaryFunction()))
                  }, _toDisplayString(_ctx.message.TextSecondary), 3))
                : _createCommentVNode("", true)
            ])),
    (_ctx.message.Type === 'WINECARD' || _ctx.message.Type === 'OFFERCARD')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_Arrow, {
            class: "navigationArrow",
            style: {"transform":"rotate(180deg)"},
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.scrollWineCards(-1)))
          }),
          _createVNode(_component_Arrow, {
            class: "navigationArrow",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.scrollWineCards(1)))
          })
        ]))
      : _createCommentVNode("", true)
  ], 2))
}