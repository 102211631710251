import { AnswerForMatching } from "@/entities/api/AnswerForMatching";
import { IQuestionResponse } from "@/interfaces/api/responses/IQuestionResponse";
import { IQuizResponse } from "@/interfaces/api/responses/IQuizResponse";
import { Answer } from "./Answer";
import { Question } from "./Question";
import Router from "@/router";
import { QuestionType } from "@/entities/enums/QuizEnums";

export default class Quiz {
	Id: number;
	Name: string | null;
	Questions: Question[];

	QuestionIdHistory: number[];
	SelectedAnswers: AnswerForMatching[];
	CurrentSelectedAnswer?: Answer;

	constructor(quiz: Quiz) {
		this.Id = quiz.Id;
		this.Name = quiz.Name;
		this.Questions = quiz.Questions;
		this.QuestionIdHistory = quiz.QuestionIdHistory ?? [];
		this.SelectedAnswers = quiz.SelectedAnswers ?? ([] as Answer[]);
		this.CurrentSelectedAnswer = quiz.CurrentSelectedAnswer;
	}

	static createFromApiResponse(response: IQuizResponse): Quiz {
		const newQuiz = new Quiz({
			Id: response.id,
			Name: response.name,
			Questions: response.questions.map((question: IQuestionResponse) =>
				Question.createFromApiResponse(question)
			),
			QuestionIdHistory: [] as number[],
			SelectedAnswers: [] as AnswerForMatching[],
		} as Quiz);

		return newQuiz;
	}

	getQuestionById(questionId: number): Question {
		if (!this.Questions || this.Questions.length == 0) return {} as Question;

		return this.Questions.find((question) => question.Id == questionId) as Question;
	}

	getQuestionCount(): number {
		if (!this.Questions) return 0;

		const uniqueQuestionPositions = this.Questions.filter(question => question.Type !== QuestionType.IntermediateScreenWaves).map((question) => question.Position).filter(
			(v, i, a) => a.indexOf(v) === i
		); // DISTINCT questions for Positions
		return uniqueQuestionPositions.length;
	}

	getCurrentQuestion(): Question {
		if (
			!this.Questions ||
			this.Questions?.length == 0 ||
			!this.QuestionIdHistory ||
			this.QuestionIdHistory.length == 0
		)
			return {} as Question;

		return this.getQuestionById(this.QuestionIdHistory[this.QuestionIdHistory.length - 1]);
	}

	static filterQuizForLanguage(quiz?: Quiz, language?: string): Quiz {
		if (!quiz) return {} as Quiz;

		// Since de is standard, no languageModifier is needed (= empty string)
		const languageModifier = language === "de" ? "" : language;

		const languageSelectedQuiz = quiz;

		const languageFilteredQuestions = quiz.Questions.map((question) => {
			const answersToReturn = question.Answers.map((answer) => {
				return {
					...answer,
					Text: answer["text_" + languageModifier] ?? answer.Text,
					TextEn: answer.Text, // needed for VS Choice
				} as Answer;
			});

			return {
				...question,
				Answers: answersToReturn,
				Text: question["text_" + languageModifier] ?? question.Text,
				Explanation: question["explanation_" + languageModifier] ?? question.Explanation,
			} as Question;
		});

		languageSelectedQuiz.Questions = languageFilteredQuestions;

		return languageSelectedQuiz;
	}

	getAnswerById(answerId: number): Answer {
		if(!this.getCurrentQuestion().Answers || this.getCurrentQuestion().Answers.length === 0)
			return {} as Answer;

		return this.getCurrentQuestion().Answers.find((ele) => {
			return ele.Id === answerId;
		}) as Answer;
	}

	async returnToPrevQuestion(): Promise<void> {
		this.QuestionIdHistory.pop();

		await Router.push("/question/" + this.getCurrentQuestion().Id);
	}
}
