import { QuestionType } from "@/entities/enums/QuizEnums";
import { IQuestionResponse } from "@/interfaces/api/responses/IQuestionResponse";
import { Answer } from "./Answer";
import { QuestionFrontendSetting } from "./QuestionFrontendSetting";

export class Question {
    Id: number;

    Answers: Answer[];
    Explanation: string | null;
    ExplanationEn: string | null;
    FrontendSettings: QuestionFrontendSetting[];
    Headline: string | null;
    Picture: string | null;
    Position: number;
    Text: string | null;
    Type: QuestionType;

    constructor(question: Question) {
        this.Id = question.Id;

        this.Answers = question.Answers;
        this.Explanation = question.Explanation;
        this.ExplanationEn = question.ExplanationEn;
        this.FrontendSettings = question.FrontendSettings;
        this.Headline = question.Headline;
        this.Picture = question.Picture;
        this.Position = question.Position;
        this.Text = question.Text;
        this.Type = question.Type;
    }

    static createFromApiResponse(response: IQuestionResponse): Question {
        const newQuestion = new Question({
            Id: response.id,

            Answers: response.answers.map((answer) => Answer.createFromApiResponse(answer)),
            Explanation: response.explanation,
            ExplanationEn: response.explanation_en,
            FrontendSettings: response.frontend_settings.map((frontendSetting) => QuestionFrontendSetting.createFromApiResponse(frontendSetting)),
            Headline: response.headline,
            Picture: response.picture,
            Position: response.position,
            Text: response.text,
            Type: QuestionType[response.questionType],
        });

        Object.keys(response).forEach(key => {

            if (key.includes("text")) {
                newQuestion[key] = response[key];
            }
            if(key.includes("explanation")) {
                newQuestion[key] = response[key];
            }

        });

        return newQuestion;
    }

}