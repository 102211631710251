import { MessageSender, MessageTypes } from "@/entities/enums/ChatbotEnums";
import { IFirstLevelSupportResponse } from "../../../interfaces/FirstLevelSupport/IFirstLevelSupportResponse";
import { ISupportFlowItemAnswerResponse } from "../../../interfaces/FirstLevelSupport/ISupportFlowItemAnswerResponse";
import { ISupportFlowItemResponse } from "../../../interfaces/FirstLevelSupport/ISupportFlowItemResponse";
import { Message } from "../Chatbot/Message";
import { Answer } from "../Quiz/Answer";
import QuizService from "@/services/QuizService";

export class FirstLevelSupport {
	Id: number;
	Name: string;
	SupportFlow: SupportFLowItem[];

	MessageHistory: SupportMessage[] = [];
	CurrentQuestion?: string;

	IsLoading = false;

	DefaultContinueText = "Benötigst du noch weitere Hilfe?";

	private _scrollFunction?: () => void = undefined;

	constructor(firstLevelSupport: FirstLevelSupport) {
		this.Id = firstLevelSupport.Id;
		this.Name = firstLevelSupport.Name;
		this.SupportFlow = firstLevelSupport.SupportFlow;
	}

	set ScrollFunction(func: () => void) {
		this._scrollFunction = func;
	}

	get ScrollFunction() {
		if (this._scrollFunction) return this._scrollFunction;

		return () => {
			// empty
		};
	}

	setScrollFuntion(func: () => void) {
		this._scrollFunction = func;
	}

	static createFromApiResponse(response: IFirstLevelSupportResponse): FirstLevelSupport {
		const newSupportFlow = new FirstLevelSupport({
			Id: response.id,
			Name: response.name,
			SupportFlow: response.SupportFlow.map((item) =>
				SupportFLowItem.createFromApiResponse(item)
			),
		} as FirstLevelSupport);

		newSupportFlow.CurrentQuestion = newSupportFlow.SupportFlow[0].BotText;
		newSupportFlow.MessageHistory.push(
			{
				Id: newSupportFlow.MessageHistory.length + 1,
				Text: newSupportFlow.SupportFlow[0].BotText,
				Type: MessageTypes.NORMAL,
				Sender: MessageSender.WILLI,
				AutoScroll: true,
			},
			{
				Id: newSupportFlow.MessageHistory.length + 1,
				Text: "",
				Type: MessageTypes.ANSWERSELECTION,
				Sender: MessageSender.WILLI,
				AnswerSelection: newSupportFlow.SupportFlow[0].ItemAnswers.map((answer) => {
					return new SupportAnswer({
						Id: answer.Id,
						Text: answer.AnswerText,
						Value: answer.Id,
						NextQuestion: answer.NextItemId,
						Type: answer.AnswerType,
					} as SupportAnswer);
				}),
				AnswerAction: newSupportFlow.SetAnswerForQuiz,
			}
		);

		return newSupportFlow;
	}

	SetAnswerForQuiz(answer: Answer) {
		this.MessageHistory.pop();

		this.MessageHistory.push({
			Id: this.MessageHistory.length + 1,
			Text: answer.Text ?? "",
			Type: MessageTypes.NORMAL,
			Sender: MessageSender.USER,
		});

		this._scrollFunction?.();

		this.IsLoading = true;

		const questionOfAnswer = this.SupportFlow.find((el) =>
			el.ItemAnswers.find((a) => a.Id === answer.Id)
		);

		if (!questionOfAnswer) {
			this.IsLoading = false;
			return;
		}

		const questionIndexOfAnswer = this.SupportFlow.indexOf(questionOfAnswer);
		const nextQuestionAfterIndex = this.SupportFlow[questionIndexOfAnswer + 1];

		setTimeout(() => {
			// Push next question
			this.pushNextFlowItem(
				answer.NextQuestion?.toString() ??
					questionOfAnswer?.NextItemId ??
					nextQuestionAfterIndex.IdInternal
			);

			this.IsLoading = false;
			this._scrollFunction?.();
		}, Math.random() * (1500 - 500) + 500);
	}

	StartTracking(answer: Answer) {
		this.MessageHistory.pop();

		this.MessageHistory.push(
			{
				Id: this.MessageHistory.length + 1,
				Text: answer.Text ?? "",
				Type: MessageTypes.NORMAL,
				Sender: MessageSender.USER,
			},
			{
				Id: this.MessageHistory.length + 1,
				Text: "Gebe deine Sendungsnummer ein:",
				Type: MessageTypes.NORMAL,
				Sender: MessageSender.WILLI,
			}
		);
		this._scrollFunction?.();
	}

	async GetTracking(trackingNumber: string, language: string) {
		this.MessageHistory.pop();

		this.MessageHistory.push({
			Id: this.MessageHistory.length + 1,
			Text: trackingNumber,
			Type: MessageTypes.NORMAL,
			Sender: MessageSender.USER,
		});

		this.IsLoading = true;

		const languageLocal = language && language !== "" ? language : "de";

		let trackingResult: any;
		try {
			trackingResult = await QuizService.trackPackage(trackingNumber, languageLocal);
			this.IsLoading = false;

			this.MessageHistory.push({
				Id: this.MessageHistory.length + 1,
				Text:
					trackingResult.message +
					"\nWeitere Informationen finden Sie unter: \n\n<a>" +
					trackingResult.serviceUrl +
					"</a>",
				Type: MessageTypes.NORMAL,
				Sender: MessageSender.WILLI,
			});
		} catch (error: any) {
			this.IsLoading = false;

			this.MessageHistory.push({
				Id: this.MessageHistory.length + 1,
				Text: "Zu dieser Sendungsnummer liegen uns keine Informationen vor.",
				Type: MessageTypes.NORMAL,
				Sender: MessageSender.WILLI,
			});
		}

		this.IsLoading = true;

		setTimeout(() => {
			this.MessageHistory.push(
				{
					Id: this.MessageHistory.length + 1,
					Text: this.DefaultContinueText,
					Type: MessageTypes.NORMAL,
					Sender: MessageSender.WILLI,
					AutoScroll: true,
				},
				{
					Id: this.MessageHistory.length + 1,
					Text: "",
					Type: MessageTypes.ANSWERSELECTION,
					Sender: MessageSender.WILLI,
					AnswerSelection: [
						...this.SupportFlow[0].ItemAnswers.map((answer) => {
							return new SupportAnswer({
								Id: answer.Id,
								Text: answer.AnswerText,
								Value: answer.Id,
								NextQuestion: answer.NextItemId,
								Type: answer.AnswerType,
							} as SupportAnswer);
						}),
						this.getDefaultEndAnswer(),
					],
					AnswerAction: this.SetAnswerForQuiz,
				}
			);

			this.IsLoading = false;
			this._scrollFunction?.();
		}, Math.random() * (1500 - 500) + 500);
	}

	getDefaultEndAnswer() {
		return new SupportAnswer({
			Id: 0,
			IdInternal: "endanswer",
			Text: "Danke, ich benötige keine weitere Hilfe",
			Value: "endanswer",
			Type: "CLOSE",
		} as SupportAnswer);
	}

	pushNextFlowItem(id: string) {
		const nextItem = this.SupportFlow.find((el) => el.IdInternal === id);

		if (!nextItem) {
			this.IsLoading = true;
			setTimeout(() => {
				this.MessageHistory.push(
					{
						Id: this.MessageHistory.length + 1,
						Text: this.DefaultContinueText,
						Type: MessageTypes.NORMAL,
						Sender: MessageSender.WILLI,
						AutoScroll: true,
					},
					{
						Id: this.MessageHistory.length + 1,
						Text: "",
						Type: MessageTypes.ANSWERSELECTION,
						Sender: MessageSender.WILLI,
						AnswerSelection: [
							...this.SupportFlow[0].ItemAnswers.map((answer) => {
								return new SupportAnswer({
									Id: answer.Id,
									Text: answer.AnswerText,
									Value: answer.Id,
									NextQuestion: answer.NextItemId,
									Type: answer.AnswerType,
								} as SupportAnswer);
							}),
							this.getDefaultEndAnswer(),
						],
						AnswerAction: this.SetAnswerForQuiz,
					}
				);

				this._scrollFunction?.();
				this.IsLoading = false;
			}, Math.random() * (1500 - 500) + 500);

			return;
		}

		this.CurrentQuestion = nextItem.BotText;
		this.MessageHistory.push({
			Id: this.MessageHistory.length + 1,
			Text: nextItem.BotText,
			Type: MessageTypes.NORMAL,
			Sender: MessageSender.WILLI,
			AutoScroll: true,
		});

		this._scrollFunction?.();

		if (nextItem.ItemAnswers.length !== 0) {
			this.MessageHistory.push({
				Id: this.MessageHistory.length + 1,
				Text: "",
				Type: MessageTypes.ANSWERSELECTION,
				Sender: MessageSender.WILLI,
				IdInternal: nextItem.IdInternal,
				AnswerSelection: nextItem.ItemAnswers.map((answer) => {
					return new SupportAnswer({
						Id: answer.Id,
						Text: answer.AnswerText,
						Value: answer.Id,
						NextQuestion: answer.NextItemId,
						Type: answer.AnswerType,
						IdInternal: answer.IdInternal,
					} as SupportAnswer);
				}),
			});
		} else {
			this.IsLoading = true;
			setTimeout(() => {
				this.IsLoading = false;
				this.pushNextFlowItem(nextItem.NextItemId ?? "0");
			}, Math.random() * (1500 - 500) + 500);
		}

		this._scrollFunction?.();
	}
}

export class SupportFLowItem {
	Id: number;
	BotText: string;
	NextItemId?: string | null;
	IdInternal: string;
	ItemAnswers: SupportFlowItemAnswer[];

	constructor(supportFLowItem: SupportFLowItem) {
		this.Id = supportFLowItem.Id;
		this.IdInternal = supportFLowItem.IdInternal;
		this.BotText = supportFLowItem.BotText;
		this.NextItemId = supportFLowItem.NextItemId;
		this.ItemAnswers = supportFLowItem.ItemAnswers;
	}

	static createFromApiResponse(response: ISupportFlowItemResponse): SupportFLowItem {
		return new SupportFLowItem({
			Id: response.id,
			BotText: response.BotText,
			NextItemId: response.NextQuestion,
			IdInternal: response.id_internal,
			ItemAnswers: response.FlowAnswer.map((answer) =>
				SupportFlowItemAnswer.createFromApiResponse(answer)
			),
		} as SupportFLowItem);
	}
}

export class SupportFlowItemAnswer {
	Id: number;
	AnswerText: string;
	AnswerType: SupportFlowItemAnswerType;
	NextItemId?: string | null;
	IdInternal: string;

	constructor(supportFlowItemAnswer: SupportFlowItemAnswer) {
		this.Id = supportFlowItemAnswer.Id;
		this.AnswerText = supportFlowItemAnswer.AnswerText;
		this.AnswerType = supportFlowItemAnswer.AnswerType;
		this.NextItemId = supportFlowItemAnswer.NextItemId;
		this.IdInternal = supportFlowItemAnswer.IdInternal;
	}

	static createFromApiResponse(response: ISupportFlowItemAnswerResponse): SupportFlowItemAnswer {
		return new SupportFlowItemAnswer({
			Id: response.id,
			AnswerText: response.AnswerText,
			AnswerType: response.AnswerType as SupportFlowItemAnswerType,
			NextItemId: response.NextQuestion,
			IdInternal: response.id_internal,
		});
	}
}

export class SupportMessage extends Message {
	AnswerSelection?: SupportAnswer[];
	IdInternal?: string;

	constructor(message: SupportMessage) {
		super(message);
		this.AnswerSelection = message.AnswerSelection;
	}
}

export class SupportAnswer extends Answer {
	Type: SupportFlowItemAnswerType;
	IdInternal: string;

	constructor(answer: SupportAnswer) {
		super(answer);
		this.Type = answer.Type;
		this.IdInternal = answer.IdInternal;
		this.NextQuestion = answer.NextQuestion;
	}
}

export enum SupportFlowItemAnswerType {
	STANDARD = "STANDARD",
	LINK = "LINK",
	PACKAGE_TRACKING = "PACKAGE_TRACKING",
	WINE_CONSULTANT = "WINE_CONSULTANT",
	CLOSE = "CLOSE",
	SELECT = "SELECT",
}

export enum InputModes {
	NONE = "NONE",
	TRACKING = "TRACKING",
	MESSAGE = "MESSAGE",
	SELECT = "SELECT",
}
