
import { defineComponent, PropType } from "vue";

import FeedbackStars from "@/components/Elements/FeedbackStars.vue";
import FloatingArrows from "@/components/BaseComponents/FloatingArrows.vue";

import PrinterService from "@/services/PrinterService";
import FooterWave from "@/components/Elements/FooterWave.vue";
import { Translations } from "@/entities/domain/Translations";
import DialogComponent from "@/components/Dialog.vue";
import Restaurant from "@/entities/domain/Restaurant/Restaurant";
import { Wine } from "@/entities/domain/Match/Wine";
import { WineButtonAction } from "@/entities/enums/QuizConfigEnums";
import WineCard from "./WineCard.vue";
import WineCardV2 from "@/components/BaseComponents/WineCardV2.vue";
import PartnerMatching from "./PartnerMatching.vue";
import WineGlassButton from "@/components/BaseComponents/WineGlassButton.vue";
import TasteType from "./TasteType.vue";
import BottleCard from "./BottleCard.vue";
import EmailForm from "@/components/Forms/EmailForm.vue";
import StoreNavigationMap from "../StoreNavigationMap.vue";
import GetDocumentElementById from "@/utils/GetDocumentElementById";


import QrcodeVue from "qrcode.vue";
import QuizService from "@/services/QuizService";
import { Operator, WineFilter, WineListFilter } from "@/entities/domain/Match/WineListFilter";
import { WineFeedback } from "@/entities/domain/Match/WineFeedback";

export default defineComponent({
	name: "ProfileChoice",
	components: {
    FloatingArrows,
    FooterWave,
    DialogComponent,
    FeedbackStars,
    WineCard,
    WineGlassButton,
    TasteType,
    BottleCard,
    EmailForm,
    StoreNavigationMap,
    QrcodeVue,
    WineCardV2
},
	props: {
		restaurant: {
			type: Object as PropType<Restaurant>,
			required: true,
		},
	},
	emits: ["answerClicked", "setAnswer", "closeQuiz"],
	data() {
		return {
			delay: 2000,
			openEmailDialog:
				!this.restaurant?.Match?.ProvidedContact &&
				!this.restaurant.Match?.ClaimedWine &&
				this.restaurant.RestaurantCode === "TW-MESSE",
			loadingError: false,
			wineSelected: null as Wine | null,
			map: undefined,
			wineImageSelected: null as Wine | null,
			userReadDialog: false,

			isDialogOpened: false,

			wineListFilterOptions: new WineListFilter(),
			filteredWineList: this.restaurant.Match?.Wines as Wine[],

			firstV2CardFlipped: true,

			// for Demo
			// emailQuestionProvided: true,
		};
	},
	computed: {
		IGetNowMode(): boolean {
			return !!window["__IGNDL"];
		},
		innerWidth(): number {
			return window.innerWidth;
		},
		baseComponentTranslations(): Translations {
			return this.restaurant?.baseComponentTranslations ?? {};
		},
		emailFormRejectText(): string {
			if (this.IGetNowMode)
				return this.baseComponentTranslations?.bookmark?.text ?? "Vormerken";
			else if (
				this.restaurant.RestaurantQuizConfig.EndscreenWineButtonAction ===
				WineButtonAction.REDIRECTWITHWARNING
			)
				return this.baseComponentTranslations?.skip?.text ?? "Überspringen";
			else return this.baseComponentTranslations?.close?.text ?? "Schließen";
		},
		emailDialogText(): string {
			if (
				this.restaurant.RestaurantQuizConfig.EndscreenWineButtonAction ===
				WineButtonAction.REDIRECTWITHWARNING
			)
				return this.baseComponentTranslations?.save?.text ?? "Speichern";

			return this.baseComponentTranslations?.send?.text ?? "Absenden";
		},
		showFeedbackQuestion(): boolean {
			if (
				this.restaurant.isEmailQuestionProvided ||
				this.userReadDialog ||
				!this.restaurant.RestaurantQuizConfig.OpenEmailDialogAfter
			)
				return true;

			return false;
		},
		headline(): string {

			if (this.restaurant.RestaurantQuizConfig.EndscreenWineButtonAction ===
				WineButtonAction.STARTTASTING)
				return this.restaurant.DisplayedTexts["Diese Weine kannst du probieren"];

			if (this.restaurant.RestaurantQuizConfig.UseFormalSalutation)
				return this.restaurant.DisplayedTexts["Diese Weine passen perfekt zu Ihnen:"]

			return this.restaurant.DisplayedTexts["Diese Weine passen perfekt zu dir:"]
		},
	},
	created() {
		// Send Data to API to get Results
		sessionStorage.removeItem("infoWilliSlider");
	},
	mounted() {
		if (this.restaurant?.Match) {
			this.wineSelected =
				this.restaurant.Match.Wines.find((wine) => {
					// TODO: fix Typesafetyness on this
					const dataToCompare =
						this.restaurant.Quiz.getCurrentQuestion().FrontendSettings.find(
							(el) => el["shelfNumberToNavigate"]
						);

					return (
						wine.ProductLocation?.ShelfNumber ===
						(dataToCompare ? dataToCompare["shelfNumberToNavigate"] : null)
					);
				}) ?? null;

			if (this.wineSelected) this.isDialogOpened = true;
		}

		// When RestartButton is shown, autoreload should also be enabled
		if (
			this.restaurant.RestaurantQuizConfig.ShowRestartButton &&
			process.env.NODE_ENV !== "development"
		)
			setTimeout(() => this.restaurant.restartQuiz(), 300000);

		if (this.restaurant.RestaurantQuizConfig.OpenEmailDialogAfter)
			setTimeout(() => {
				// if (!this.restaurant.isEmailQuestionProvided) {
				this.openEmailDialog = true;
				this.isDialogOpened = true;
				// }
			}, this.restaurant.RestaurantQuizConfig.OpenEmailDialogAfter);

		setTimeout(() => {
			this.firstV2CardFlipped = false;
		}, this.delay);
	},
	methods: {
		closeDialog() {
			this.wineSelected = null;
			this.wineImageSelected = null;
			this.isDialogOpened = false;
		},
		scrollToTasteProfile() {
			GetDocumentElementById("tasteProfile")?.scrollIntoView({
				behavior: "smooth",
				block: "start",
				inline: "nearest",
			});
		},

		markdownText(text: string) {
			const count = text.match(/(\*\*)/g);
			let result = text;
			if (count && count.length > 0) {
				for (let i = 0; i < count.length; i++) {
					result = result.replace("**", "<span style='font-weight: bold;'>");
					result = result.replace("**", "</span>");
				}
			}

			return result;
		},
		isWineAvailable(wine: Wine): boolean {
			if (this.IGetNowMode) return !!wine.IgnId;
			else if (
				this.restaurant?.RestaurantQuizConfig.EndscreenWineButtonAction ===
				WineButtonAction.OPENNAVIGATIONMAP
			) {
				return true;
			} else if (
				this.restaurant?.RestaurantQuizConfig.EndscreenWineButtonAction ===
				WineButtonAction.SAVEMODE
				||
				this.restaurant?.RestaurantQuizConfig.EndscreenWineButtonAction ===
				WineButtonAction.STARTTASTING
			) {
				return true;
			} else return !!wine.Handle;
		},
		async onWineClicked(wine: Wine) {
			this.wineImageSelected = null;
			this.wineSelected = wine;

			const gtins = await this.restaurant.getBtgGtins();

			if (
				!this.IGetNowMode &&
				wine.Handle &&
				this.restaurant.RestaurantQuizConfig.EndscreenWineButtonAction !==
				WineButtonAction.SAVEMODE &&
				this.restaurant.RestaurantQuizConfig.EndscreenWineButtonAction !==
				WineButtonAction.OPENNAVIGATIONMAP &&
				this.restaurant?.RestaurantQuizConfig.EndscreenWineButtonAction !==
				WineButtonAction.STARTTASTING &&
				(this.restaurant.RestaurantQuizConfig.EndscreenWineButtonAction !==
					WineButtonAction.REDIRECTWITHWARNING ||
					this.restaurant.isEmailQuestionProvided)
			) {
				QuizService.sendEvent(JSON.stringify({ source: "quiz", wine: wine.Id }), "LinkOpened")
				window
					.open(
						(this.restaurant.Match?.RestaurantConfig?.ShopifyShopProductPrefix ?? "") +
						wine.Handle,
						"_blank"
					)
					?.focus();
			} else if (
				this.restaurant.RestaurantQuizConfig.EndscreenWineButtonAction ===
				WineButtonAction.REDIRECTWITHWARNING
			) {
				this.openEmailDialog = true;
				this.isDialogOpened = true;
			} else if (this.IGetNowMode) this.$emit("closeQuiz");
			else if (this.restaurant.RestaurantQuizConfig.EndscreenWineButtonAction === WineButtonAction.STARTTASTING || (this.restaurant.RestaurantQuizConfig.BTGMode && gtins && gtins.includes(wine.GTIN))) {
				window.close()
			}
			else {
				QuizService.sendEvent(JSON.stringify({ source: "quiz", wine: wine.Id }), "NavigationOpened")
				this.isDialogOpened = true;
			}
		},
		emailFormReject() {
			if (
				this.restaurant.RestaurantQuizConfig.EndscreenWineButtonAction ===
				WineButtonAction.REDIRECTWITHWARNING &&
				this.wineSelected
			) {
				QuizService.sendEvent(JSON.stringify({ source: "quiz", wine: this.wineSelected.Id }), "LinkOpened")
				window
					.open(
						this.restaurant.Match?.RestaurantConfig?.ShopifyShopProductPrefix +
						this.wineSelected.Handle,
						"_blank"
					)
					?.focus();

				this.isDialogOpened = false;
			}
		},
		onWineImageClicked(wine: Wine) {
			this.wineSelected = null;
			this.wineImageSelected = wine;
			this.openEmailDialog = true;
		},
		wineIsMealMatch(wine) {
			return wine.mealMatch > 0;
		},
		async printWineBon() {
			// QuizService.printDemo();

			const topWines = this.filteredWineList.slice(0, 3);

			if (!topWines || topWines.filter((wine) => !wine.ProductLocation).length > 0) return;

			const wines = topWines.map((wine) => {
				return {
					name: wine.Name,
					shelf: `${this.restaurant?.RestaurantCode}_x5F_${wine.ProductLocation!.ShelfNumber
						}`,
					rebsorte: wine.Grape,
				};
			});

			if (wines?.length) {
				const mapSvgElement = GetDocumentElementById(wines[0].shelf);
				const svg = mapSvgElement?.closest("svg");

				const url = await PrinterService.svgToURI(
					svg,
					wines.map((w) => w.shelf)
				);
				const requestOptions = {
					method: "POST",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						image: url,
						wines: wines,
					}),
				};
				await fetch("http://localhost:8080/print", requestOptions);
			}
		},
		getPartnerMatch() {
			return parseInt(this.restaurant?.Match?.Wines[0] ? this.restaurant?.Match?.Wines[0].Handle : '')
		},
		noMealMatchGiven() {
			return (
				this.restaurant?.Match?.Wines.filter((wine) => this.wineIsMealMatch(wine))
					.length === 0
			);
		},
		getTextPartBeforeUnderline(text: string | null | undefined): string {
			if (!text) return "Was macht deinen";

			return text.substring(0, text.indexOf("<underline>"));
		},
		getTextPartInbetweenUnderline(text: string | null | undefined): string {
			if (!text) return "Lieblingswein";

			return text.substring(text.indexOf("<underline>") + 11, text.indexOf("</underline>"));
		},
		getTextPartAfterUnderline(text: string | null | undefined): string {
			if (!text) return "aus?";

			return text.substring(text.indexOf("</underline>") + 12);
		},
		emailFormSended() {
			if (
				this.restaurant.RestaurantQuizConfig.EndscreenWineButtonAction ===
				WineButtonAction.REDIRECTWITHWARNING &&
				this.wineSelected &&
				this.restaurant.Match?.RestaurantConfig
			)
				window
					.open(
						this.restaurant.Match.RestaurantConfig.ShopifyShopProductPrefix +
						this.wineSelected.Handle,
						"_blank"
					)
					?.focus();
		},
		onWineFeedbackCauseSelected(feedbackData: WineFeedback) {
			// Remove bad rated Wine from matchingResult
			// Filter the Wine ID to make sure the disliked wine can't be displayed again
			// Add Filter for the Cause of the dislike, to remove similar wines
			this.wineListFilterOptions.WineFilterOptions = [
				...this.wineListFilterOptions.WineFilterOptions,
				new WineFilter({
					PropertyName: "Id",
					Operator: Operator.NotEqual,
					ValueToCompare: feedbackData.Wine.Id
				} as WineFilter)
			]

			this.wineListFilterOptions.WineFilterOptions = [
				...this.wineListFilterOptions.WineFilterOptions,
				new WineFilter({
					PropertyName: feedbackData.Cause,
					Operator: Operator.NotEqual,
					ValueToCompare: feedbackData.Wine[feedbackData.Cause]
				} as WineFilter)
			]

			this.filteredWineList = this.wineListFilterOptions.FilterWineList(this.restaurant.Match?.Wines ?? [], this.restaurant.RestaurantQuizConfig.AdvancedWineResultFiltering);
		}

	},
});
