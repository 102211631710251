
import Restaurant from "@/entities/domain/Restaurant/Restaurant";
import { WineColor } from "@/entities/enums/QuizConfigEnums";
import { defineComponent, PropType } from "vue";

export default defineComponent({
	name: "BottleCard",
	props: {
		restaurant: {
			type: Object as PropType<Restaurant>,
			required: true,
		},
		wineColor: {
			type: String,
			required: true,
		},
	},
	computed: {
		grapes(): string[] {
			return (
				(this.wineColor === WineColor.RED
					? this.restaurant.Match?.Grapes.Red
					: this.restaurant.Match?.Grapes.White) ?? []
			);
		},
		label(): string {
			if (this.wineColor === WineColor.RED)
				return (
					this.restaurant.DisplayedTexts["Passende Rotweine"]
				);

			return (
				this.restaurant.DisplayedTexts["Passende Weissweine"]
			);
		},
	},
});
