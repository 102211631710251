import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/2231_tw_flasche_weisswein.svg'
import _imports_1 from '@/assets/img/2231_tw_flasche_rotwein.svg'


const _withScopeId = n => (_pushScopeId("data-v-c96f7528"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  src: _imports_0
}
const _hoisted_2 = {
  key: 1,
  src: _imports_1
}
const _hoisted_3 = { class: "wineInfos" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["bottleCard", { whiteWine: _ctx.wineColor === 'WHITE', redWine: _ctx.wineColor === 'RED' }])
  }, [
    (_ctx.wineColor === 'WHITE')
      ? (_openBlock(), _createElementBlock("img", _hoisted_1))
      : (_openBlock(), _createElementBlock("img", _hoisted_2)),
    _createElementVNode("div", _hoisted_3, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.grapes, (grape) => {
        return (_openBlock(), _createElementBlock("div", {
          key: grape,
          class: "singleWineType"
        }, _toDisplayString(grape), 1))
      }), 128))
    ])
  ], 2))
}