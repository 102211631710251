
import { defineComponent, PropType } from "vue";

import Question from "@/components/Question.vue";
import Navigation from "@/components/Elements/Navigation.vue";
import Restaurant from "@/entities/domain/Restaurant/Restaurant";
import ProgressBar from "@/components/Elements/ProgressBar.vue";

export default defineComponent({
	name: "QuestionPage",
	components: {
		Question,
		Navigation,
		ProgressBar,
	},
	props: {
		restaurant: {
			type: Object as PropType<Restaurant>,
			default: {} as Restaurant,
		},
	},
	async mounted() {
		console.log("QuestionPage: ", this.restaurant?.Quiz.QuestionIdHistory.length);

		if (
			!this.restaurant?.Quiz.QuestionIdHistory ||
			this.restaurant?.Quiz.QuestionIdHistory.length <= 0
		) {
			console.log("pushed to home");
			await this.$router.push("/");
		}
	},
});
