
import Restaurant from "@/entities/domain/Restaurant/Restaurant";
import { defineComponent } from "vue";
import { RouterView } from "vue-router";
import Loading from "./BaseComponents/Loading.vue";
import HeaderComponent from "@/components/Elements/Header.vue";
import FooterComponent from "./Elements/Footer.vue";
import QuizService from "@/services/QuizService";
import GetDocumentElementById from "@/utils/GetDocumentElementById";
import { Languages } from "@/entities/enums/QuizConfigEnums";

export default defineComponent({
	name: "Quiz",
	components: { HeaderComponent, Loading, RouterView, FooterComponent },
	data() {
		return {
			restaurant: new Restaurant(),
			loadingError: false,
		};
	},
	props: {
		autoRedirect: {
			type: Boolean,
			default: false,
		},
		restaurantCode: {
			type: String,
			required: true,
		},
		mode: {
			type: String,
			default: "normal",
		},
		transparentMode:
		{
			type: Boolean,
			default: false,
		},
		matchInfo: 
		{
			type: String,
			default: ""
		},
		language: {
			type: String,
			default: "de",
		},
	},
	computed: {
		quizIsProperlyLoaded(): boolean {
			return (
				this.restaurant.isRestaurantLoaded &&
				this.restaurant.Quiz.QuestionIdHistory.length > 0
			);
		},
	},
	async mounted() {
		console.log("restaurantCode from HTML Snippet: " + this.restaurantCode);
		console.log("language from snippet: ", this.language);
		localStorage.setItem("language", this.language);

		// Make Info Willi visible when new Session is loaded
		sessionStorage.removeItem("infoWilliSlider");

		this.restaurant.AutoRedirect = this.autoRedirect;

		// if (this.$route.name && this.$route.name != "Chatbot") await this.$router.push("/");

		let restaurantCodeToLoadQuizFor =
			window.localStorage.getItem("restaurantCode") ?? this.restaurantCode;

		// If RestaurantCode is provided in url, use that
		const urlHashData = window.location.hash.slice(2); // Get everything after #/ in url

		if (
			urlHashData &&
			!urlHashData.includes("/") &&
			!this.$router.options.routes.find((route) => {
				if (route.path.includes("/:"))
					return urlHashData.includes(
						route.path.substring(1, route.path.indexOf("/:") - 1)
					);

				return urlHashData.includes(route.path.slice(1));
			})
			&& !["BESCHREIBUNG","AUSZEICHNUNGEN","LEBENSMITTELNACHWEIS"].some(keyword =>urlHashData.toUpperCase().includes(keyword.toUpperCase()))
		) {
			console.log("new RestaurantCode ", urlHashData.toUpperCase());

			restaurantCodeToLoadQuizFor = urlHashData.toUpperCase();
		}

		console.log("load", restaurantCodeToLoadQuizFor);

		await this.restaurant.loadSavedSessionAsync(this.matchInfo);
		const loadRestaurantResponse = await this.restaurant.loadRestaurantAsync(
			restaurantCodeToLoadQuizFor
		);

		// Set Language 
		this.restaurant.setLanguage(Languages[localStorage.getItem("language")!]);
		

		// console.log("Result Restaurant Load", loadRestaurantResponse)
			
		// if ((!loadRestaurantResponse.Data || !loadRestaurantResponse.Success) && restaurantCodeToLoadQuizFor !== this.restaurantCode) {
		// 	const newResult = await this.restaurant.loadRestaurantAsync(this.restaurantCode, true)
		// 	console.log("new Result", newResult)
		// }

		// TODO: set this somewhere else?
		// Activate Matomo Screenshots
		// window["__paq"].push(["HeatmapSessionRecording::enable"]);

		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					QuizService.sendEvent(true, "quizOpened");
				}
			});
		});

		const component = GetDocumentElementById("questionWrapper");
		if (component) observer.observe(component);

		setTimeout(()=>{

			if(this.mode === "normal" && this.restaurant.RestaurantQuizConfig.WilliAsFeature){
				console.log("set normal mode")
				this.restaurant.RestaurantQuizConfig.WilliAsFeature.RunInFeatureMode = false;
				this.restaurant.isQuizClosed = false;
			}
			
			if(this.transparentMode)
			{
				console.log("set transparent mode")
				this.restaurant.homepageMode = true;
				this.restaurant.RestaurantQuizConfig.TransparentMode = true;
			}
		}, 200)
	},
	methods: {
		reloadQuiz() {
			window.location.reload();
		},
	},
});
