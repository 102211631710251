export enum MessageTypes {
	NORMAL = "NORMAL",
	IGNOREINHISTORY = "IGNOREINHISTORY",
	WINECARD = "WINECARD",
	OFFERCARD = "OFFERCARD",
	ONEBUTTON = "ONEBUTTON",
	TWOBUTTONS = "TWOBUTTONS",
	ANSWERSELECTION = "ANSWERSELECTION"
}

export enum MessageSender {
	USER,
	WILLI,
}

export enum ButtonStyle {
	NORMAL = "NORMAL",
	PRIMARY = "PRIMARY",
	SECONDARY = "SECONDARY",
}

export enum ChatbotState {
	CLOSED = "CLOSED",
	OPENED = "OPENED",
	SUPPORT = "SUPPORT",
	SUPPORT_CHAT = "SUPPORT_CHAT",
	HIDDEN = "HIDDEN"
}