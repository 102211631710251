
import Restaurant from "@/entities/domain/Restaurant/Restaurant";
import PrinterService from "@/services/PrinterService";
import { defineComponent, PropType } from "vue";
import QrcodeVue from "qrcode.vue";
import GetDocumentElementById from "@/utils/GetDocumentElementById";
import { NavigationMode } from "@/entities/enums/QuizConfigEnums";
import { Wine } from "@/entities/domain/Match/Wine";
import QuerySelectorAll from "@/utils/QuerySelectorAll";

// TODO: Pfad-Navigation aktuell ausgeblendet und nicht implementiert

export default defineComponent({
	name: "StoreNavigationMap",
	components: {
		QrcodeVue,
	},
	props: {
		restaurant: {
			type: Object as PropType<Restaurant>,
			required: true,
		},
		shelfNumber: {
			type: String || undefined,
		},
		selectedWine: {
			type: Wine || null
		}
	},
	data() {
		return {
			blink: false,
			svgData: "",
		};
	},
	computed: {
		restaurantCode() {
			return this.restaurant?.RestaurantCode;
		},
	},
	watch: {
		shelfNumber(newValue, oldValue) {
			if(this.restaurant.RestaurantQuizConfig.NavigationMode === NavigationMode.NORMAL) {
				if (oldValue)
				GetDocumentElementById(this.restaurantCode + "_x5F_" + oldValue)?.classList.remove(
					"active"
				);
					
				GetDocumentElementById(this.restaurantCode + "_x5F_" + newValue)?.classList.add(
					"active"
				);				
			}
			
		},
		selectedWine(newValue: Wine, oldValue: Wine) {
			if(this.restaurant.RestaurantQuizConfig.NavigationMode === NavigationMode.SEARCH)
			{

				let oldName = this.restaurantCode + "_" + oldValue.Country.toLocaleLowerCase();

				if(oldValue.Region)
					oldName += "_" + oldValue.Region.toLocaleLowerCase()

				oldName += oldValue.Grape.toLocaleLowerCase();

				let newName = this.restaurantCode + "_" + newValue.Country.toLocaleLowerCase();

				if(newValue.Region)
					newName += "_" + newValue.Region.toLocaleLowerCase()

				newName += newValue.Grape.toLocaleLowerCase();

				if (oldValue)
				GetDocumentElementById(oldName)?.classList.remove("active");
					
				GetDocumentElementById(newName)?.classList.add("active");
			}
		},
		restaurantCode(newVal, oldValue) {
			if (oldValue) {
				GetDocumentElementById(this.restaurantCode + "_x5F_" + oldValue)?.classList.remove(
					"active"
				);
			}

			GetDocumentElementById(this.restaurantCode + "_x5F_" + this.shelfNumber)?.classList.add(
				"active"
			);
		},
		blink(newVal) {
			this.setActive(newVal);
		},
	},
	mounted() {
		let name = null as string | null;

		if(this.restaurant.RestaurantQuizConfig.NavigationMode === NavigationMode.SEARCH){
			name = this.findMostMatchedId();
		}
		else
			name = this.restaurantCode + "_x5F_" + this.shelfNumber

		console.log(name);
		
		if(!name) return;
		
		GetDocumentElementById(name)?.classList.add(
			"active"
		);

		setInterval(() => {
			this.blink = !this.blink;
		}, 500);
	},
	methods: {
		setActive(blink) {
			let name = null as string | null;

			if(this.restaurant.RestaurantQuizConfig.NavigationMode === NavigationMode.SEARCH){
				name = this.findMostMatchedId();
			}
			else
				name = this.restaurantCode + "_x5F_" + this.shelfNumber

			if(!name) return;
		
			if (blink) {
				GetDocumentElementById(
					name
				)?.classList.add("active");
			} else {
				GetDocumentElementById(
					name
				)?.classList.remove("active");
			}
		},
		async printWineBon() {
			// QuizService.printDemo();

			const wines = this.restaurant?.Match?.Wines.slice(0, 3).map((wine) => {
				return {
					name: wine.Name,
					shelf: wine.ProductLocation
						? this.restaurantCode + "_x5F_" + wine.ProductLocation.ShelfNumber
						: "",
					rebsorte: wine.Grape,
				};
			});

			if (wines && wines.length) {
				// Find first with shelfNumber
				const referenceWine = wines.find((wine) => wine.shelf !== null);

				if (!referenceWine) return;

				const mapSvgElement = GetDocumentElementById(referenceWine.shelf);
				const svg = mapSvgElement?.closest("svg");

				const url = await PrinterService.svgToURI(
					svg,
					wines.map((w) => w.shelf)
				);
				const requestOptions = {
					method: "POST",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						image: url,
						wines: wines,
					}),
				};
				await fetch("http://localhost:8080/print", requestOptions);
			}
		},
		findMostMatchedId(){
			var elements = QuerySelectorAll('[id]');

			if(!elements) return null;

			// Object to store ID matches count
			var idMatches = {};

			// Iterate through elements and count matches for each ID
			for (var i = 0; i < elements.length; i++) {
				var element = elements[i];
				var id = element.id;
				var matchCount = 0;
				
				if (this.selectedWine?.Country && id.includes(this.selectedWine?.Country.toLocaleLowerCase())) {
					matchCount += 2;
				}
				if (this.selectedWine?.Region && id.includes(this.selectedWine?.Region.toLocaleLowerCase())) {
					matchCount++;
				}
				if (this.selectedWine?.Color && id.includes(this.selectedWine?.Color.toLocaleLowerCase())) {
					matchCount++;
				}
			
				
				if (matchCount > 0) {
					idMatches[id] = {
					matchCount: matchCount,
					length: id.length
					};
				}
			}

			// Find the ID with the most matches
			let matchingIds = Object.keys(idMatches);

			if(matchingIds.length === 0) return null;

			let mostMatchingId = matchingIds.reduce(function(a, b) {
				if (idMatches[a].matchCount > idMatches[b].matchCount) {
					return a;
				} else if (idMatches[a].matchCount < idMatches[b].matchCount) {
					return b;
				} else {
					return idMatches[a].length < idMatches[b].length ? a : b;
				}
			}) as string | null;

			if(!this.selectedWine?.Country)
				mostMatchingId = null;

			return mostMatchingId;
		}
	},
});
