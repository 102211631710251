/*
  Gets the questions fromm the server
  This is done when the quiz is loaded,
  it checks if there is a new version,
  otherwise serve cached question.
 */

import { QuizRequestType } from "@/interfaces/WineApi/QuizRequestType";
import { InterimInfoType } from "@/interfaces/WineApi/InterimInfoType";
import { AnswerType } from "@/interfaces/WineApi/AnswerType";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { Languages } from "@/entities/enums/QuizConfigEnums";
import { IRestaurantResponse } from "@/interfaces/api/responses/IRestaurantResponse";
import { Match } from "@/entities/domain/Match/Match";
import Quiz from "@/entities/domain/Quiz/Quiz";
import { Answer } from "@/entities/domain/Quiz/Answer";
import { Question } from "@/entities/domain/Quiz/Question";
import { AnswerForMatching } from "@/entities/api/AnswerForMatching";
import { OperationResult } from "@/entities/domain/OperationResult";

const apiPath = process.env.VUE_APP_API_SERVICE_PATH;

const QuizService = {
	assetUrl: "",
	token: "",
	restaurantCode: "",

	async getQuiz(
		restaurantCode: string,
		quizId: number | undefined = undefined
	): Promise<OperationResult<IRestaurantResponse>> {
		// Get the Quiz and Restaurant Data from API
		// Without an QuizId, the first one in the Array will be served by the API

		if(!restaurantCode)
			return new OperationResult<IRestaurantResponse>({Success: false, ErrorMessage: "Error in QuizService, no Restaurant Code provided"});

		try {
			console.log("QuizService load: " + restaurantCode);

			this.restaurantCode = restaurantCode;

			const data = {
				code: restaurantCode,
				quizId: quizId,
			};

			const config: AxiosRequestConfig = {
				method: "post",
				url: apiPath + "/tool/quiz",
				headers: {
					"Content-Type": "application/json",
				},
				data: data,
			};

			// if token would be wanted but is expired, get new token (send no token in header)
			if (
				quizId &&
				this.token &&
				JSON.parse(atob(this.token.split(".")[1])).exp > Date.now() / 1000 &&
				config.headers
			) {
				config.headers.Authorization = "Bearer " + this.token;
			}

			const result = await axios(config).catch(async (err) => {
				console.log(err.response);
				// try again to get Quiz and token
				return await axios(config);
			});

			this.token = result.data.token;

			console.log("result from Backend: ", result.data);

			return new OperationResult<IRestaurantResponse>({
				Success: true,
				Data: result.data,
			})
		} catch (error) {
			console.error("Fehler beim laden des Restaurants", { error });

			return new OperationResult({Success: false, ErrorMessage: "Error in QuizService while loading Restaurant"});
		}
	},

	async sendAnswer(
		questionId: number,
		answerValue: number | string | null,
		answerObject: Answer | null = null,
		questionObject: Question | null = null,
		quizId?: number
	): Promise<void> {
		// Send After each answer as Info to Server
		// Not as async await for better performance

		console.log("Send Answer", questionId, answerValue);
		console.log("answer", answerObject);
		console.log("question", questionObject);

		const sendData: InterimInfoType = {
			questionId: questionId,
			answer: answerValue,
			answerObject: answerObject, // TODO: Check if this works with new Answer Model
			questionObject: questionObject,
			quizId: quizId,
		};

		const config: AxiosRequestConfig = {
			method: "post",
			url: apiPath + "/tool/event",
			headers: {
				Authorization: "Bearer " + this.token,
				"Content-Type": "application/json",
			},
			data: sendData,
		};

		await axios(config)
			.then((res) => console.log(res))
			.catch(async (err) => {
				console.log(err.response);
				// check if token is expired
				if (err.response.status === 403) {
					await this.getQuiz(this.restaurantCode);
					await axios(config);
				}
			});
	},

	async sendEvent(data: any, type: string, quizId?: number): Promise<void> {
		console.log("Send Event", data, type);

		const config: AxiosRequestConfig = {
			method: "post",
			url: apiPath + "/tool/event",
			headers: {
				Authorization: "Bearer " + this.token,
				"Content-Type": "application/json",
			},
			data: { answer: data, type: type, quizId },
		};

		await axios(config).catch(async (err) => {
			console.error(err.response); // check if token is expired
			if (err.response.status === 403) {
				await this.getQuiz(this.restaurantCode);
				await axios(config);
			}
		});
	},

	getAssetPath(asset: string): string {
		return this.assetUrl + asset;
	},

	setAssetUrl(assetUrl: string): void {
		this.assetUrl = assetUrl;
	},

	async getMatch(answersForMatching: AnswerForMatching[], language: Languages, gtins?: string[]): Promise<Match> {
		try {
			const dataToSend: QuizRequestType = {
				answers: answersForMatching.map((el) => {
					const valueToReturn = {
						questionId: el.QuestionId,
						answer: el.AnswerValue,
					} as AnswerType;
					if (el.AnswerValue && el.AnswerValue.indexOf(",") > -1) {
						valueToReturn.answers = el.AnswerValue.split(",");
						valueToReturn.answer = "";
					}

					return valueToReturn;
				}),
				language: language,
				btgWines: gtins
			};

			const data = JSON.stringify(dataToSend);

			const config: AxiosRequestConfig = {
				method: "post",
				url: apiPath + "/tool/match",
				headers: {
					Authorization: "Bearer " + this.token,
					"Content-Type": "application/json",
				},
				data: data,
			};

			let result;

			await axios(config)
				.then((res) => {
					result = res;
				})
				.catch(async (err) => {
					// Check if token is expired
					if (err.response.status === 403) {
						// When token expired, generate new one and get matching
						await this.getQuiz(this.restaurantCode);
						result = await axios(config);
					} else console.log(err.response);
				});

			console.log("MatchingResult in Service", result);

			const matchResponse = Match.createFromApiResponse(result.data); // TODO: Check if works

			return matchResponse;
		} catch (err) {
			console.log(err);

			return {} as Match;
		}
	},

	async getSavedSessionMatch(
		id: string,
		hash: string,
		getOffers = false
	): Promise<{ match: Match; restaurantCode: string }> {
		try {
			const result = await axios
				.post(apiPath + "/tool/session", { id: id, hash: hash })
				.catch((err) => console.log(err.response));
			// eslint-disable-next-line quotes, dot-notation
			const sessionData = result["data"];
			this.token = sessionData.token;

			const config: AxiosRequestConfig = {
				method: "post",
				url: apiPath + "/tool/match",
				headers: {
					Authorization: "Bearer " + this.token,
					"Content-Type": "application/json",
				},
			};

			if(getOffers)
				config.data = JSON.stringify({ onlyGetOffers: true});

			const matching: AxiosResponse = await axios(config);

			return {
				match: Match.createFromApiResponse(matching.data),
				restaurantCode: sessionData.restaurantCode,
			};
		} catch (error) {
			console.log(error);

			return {} as { match: Match; restaurantCode: string };
		}
	},

	filterQuizForLanguage(quiz: Quiz, selectedLanguage: Languages): Quiz {
		// Since de is standard, no languageModifier is needed (= empty string)
		const languageSelectedQuiz = quiz;

		console.log("**** filterQuizForLanguage ****", selectedLanguage)

		const languageFilteredQuestions = quiz.Questions?.map((question) => {
			const answersToReturn = question.Answers?.map((answer) => {
				return {
					...answer,
					Text: answer["text_" + selectedLanguage] ?? answer.Text,
					TextEn: answer.Text, // needed for VS Choice TODO: Check for new modeling
				} as Answer;
			});

			return {
				...question,
				Answers: answersToReturn,
				Text: selectedLanguage === Languages.DE ? question.Text : question["text_" + selectedLanguage],
				Explanation:
					selectedLanguage === Languages.DE
						? question.Explanation
						: question["explanation_" + selectedLanguage],
			} as Question;
		});

		languageSelectedQuiz.Questions = languageFilteredQuestions;

		console.log("setted filtered Quiz", languageSelectedQuiz)

		return languageSelectedQuiz;
	},

	sendChatbotQuestionFeedback(question?: string, feedback: 1 | -1 = 1): void {
		if(!question) return;

		console.log("Send Feedback", question);
		
		const config: AxiosRequestConfig = {
			method: "post",
			url: apiPath + "/tool/chat/feedback",
			headers: {
				Authorization: "Bearer " + this.token,
				"Content-Type": "application/json",
			},
			data: { feedback: feedback, question: question },
		};

		axios(config).catch(async (err) => {
			console.error(err.response); // check if token is expired
			if (err.response.status === 403) {
				await this.getQuiz(this.restaurantCode);
				await axios(config);
			}
		});
	},

	async trackPackage(packageNumber: string, language: string){
		const config: AxiosRequestConfig = {
			method: "get",
			url: apiPath + "/services/tracking?trackingNumber=" + packageNumber + "&language=" + language ?? 'de'  ,
			headers: {
				Authorization: "Bearer " + this.token,
				"Content-Type": "application/json",
			},
		};

		const res = await axios(config);
	
		return res.data;
	}
};

export default QuizService;
