import { createRouter, createMemoryHistory } from "vue-router";

import QuestionPage from "@/pages/QuestionPage.vue";
import ResultPage from "@/pages/QuizResultPage.vue";
import QuizSelectionPage from "@/pages/QuizSelectionPage.vue";
import LanguageSelectionPage from "@/pages/LanguageSelectionPage.vue";
import ChatBotModePage from "@/pages/ChatBotModePage.vue";
import RestaurantSelectionPage from "@/pages/RestaurantSelectionPage.vue";

const router = createRouter({
	history: createMemoryHistory(),
	routes: [
		{
			path: "/question/:questionId",
			name: "Question",
			component: QuestionPage,
		},
		{
			path: "/result",
			name: "Quiz Result",
			component: ResultPage,
		},
		{
			path: "/quizSelection",
			name: "Quiz Selection",
			component: QuizSelectionPage,
		},
		{
			path: "/languageSelection",
			name: "Language Selection",
			component: LanguageSelectionPage,
		},
		{
			path: "/:pathMatch(.*#.*)",
			name: "RestaurantSelection",
			component: RestaurantSelectionPage,
		},
		{
			path: "/:pathMatch(.*)*",
			name: "Chatbot",
			component: ChatBotModePage,
		},
	],
});

export default router;
