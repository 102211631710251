import { App, createApp } from "vue";

import { defineCustomElement } from "./defineCustomElementWithStyles";
import WilliWein from "./App.vue";

// import VueMatomo from "vue-matomo";
import router from "./router";
import clickOutsideHandler from "./plugins/clickOutsideHandler";

// When in Dev mode, run as normal App
// When Production, build as webComponent

if (process.env.NODE_ENV == "development") {
	const app: App<Element> = createApp(WilliWein);
	app.config.unwrapInjectedRef = true;
	// app.use(VueMatomo, {
	// 	host: "https://analytics.tailortool.de",
	// 	siteId: 2,
	// 	debug: true,
	// });

	app.use(router);

	app.use(clickOutsideHandler);

	app.mount("#willi-wein");
} else {
	// deactivate console.log
	console.log = function () {
		// do nothing
	};

	customElements.define("willi-wein", defineCustomElement(WilliWein));
}
